<template>
    <v-dialog
            v-model="viewDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
    >
        <v-card>
            <v-card-title class="success white--text">{{ detailTitle }}</v-card-title>
            <v-card-text>
                <v-tabs
                    v-model="weeklySalesDetailCurrentTab"
                    color="success"
                    slider-color="appic-green"
                >
                    <v-tab key="opening-stocks" @change="loadList('opening')">{{ $t('message.opening') }}</v-tab>
                    <v-tab key="received-stocks" @change="loadList('received')">{{ $t('message.received') }}</v-tab>
                    <v-tab key="sold-stocks" @change="loadList('sold')">{{ $t('message.sold') }}</v-tab>
<!--                    <v-tab key="incoming-stocks" @change="loadList('incoming')">{{ $t('message.incoming') }}</v-tab>-->
                    <v-spacer/>
                    <ExportTableJson
                        :export-data="{
                            openingStocks: openingStocks,
                            receivedStocks: receivedStocks,
                            soldStocks: soldStocks,
                            title: detailTitle
                        }"
                        :export-fields="headers"
                        :export-source="'stocks-weekly-detail'"
                        class="mb-1"
                    />
                </v-tabs>
                <v-tabs-items v-model="weeklySalesDetailCurrentTab">
                    <v-tab-item key="opening-stocks-list">
                        <v-overlay
                            :value="loading.opening"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-data-table
                            :fixed-header="openingStocks.length > 10"
                            :headers="headers.opening"
                            :height="openingStocks.length > 10 ? tableHeight : null"
                            :items="openingStocks"
                            :items-per-page="-1"
                            :sort-by="'Stock.age'"
                            calculate-widths
                            class="mt-3 appic-table-light specification-table"
                            dense
                            hide-default-footer
                            id="openingStocksTable"
                            item-key="Stock.id"
                        >
                            <template v-slot:item.Stock.age="{ item }">
                                <div class="font-sm" v-if="item.Stock.age < 180">{{ item.Stock.age }}</div>
                                <div class="font-sm red--text text--darken-3" v-else>180+</div>
                            </template>
                            <template v-slot:item.Stock.volume="{ item }">
                                <div class="text-end">{{ item.Stock.volume }}</div>
                            </template>
                            <template v-slot:item.Stock.openingValue="{ item }">
                                <div class="text-end">{{ numberFormat(item.Stock.openingValue, '0,0') }}</div>
                            </template>
                            <template v-slot:item.Stock.oldValue="{ item }">
                                <div class="text-end">{{ numberFormat(item.Stock.oldValue, '0,0') }}</div>
                            </template>
                            <template v-slot:footer>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <tfoot>
                                            <tr class>
                                                <td class="text-end font-weight-bold" colspan="8">{{ $t('message.total') }}</td>
                                                <td class="text-end font-weight-bold" style="width: 50px !important;">{{ openingStocksTotals.currency }}</td>
                                                <td class="text-end font-weight-bold" style="width: 85px !important;">
                                                    <span class="mr-3">{{ numberFormat(openingStocksTotals.openingValue, '0,0') }}</span>
                                                </td>
                                                <td class="text-end font-weight-bold" style="width: 85px !important;">
                                                    <span class="mr-3">{{ numberFormat(openingStocksTotals.oldValue, '0,0') }}</span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item key="received-stocks-list">
                        <v-overlay
                            :value="loading.received"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-data-table
                            :fixed-header="receivedStocks.length > 10"
                            :headers="headers.received"
                            :items="receivedStocks"
                            :height="receivedStocks.length > 10 ? tableHeight : null"
                            :items-per-page="-1"
                            :sort-by="'Stock.age'"
                            calculate-widths
                            class="mt-3 appic-table-light specification-table"
                            dense
                            hide-default-footer
                            id="receivedStocksTable"
                            item-key="Stock.id"
                        >
                            <template v-slot:item.Stock.age="{ item }">
                                <div class="font-sm" v-if="item.Stock.age < 180">{{ item.Stock.age }}</div>
                                <div class="font-sm red--text text--darken-3" v-else>180+</div>
                            </template>
                            <template v-slot:item.Stock.volume="{ item }">
                                <div class="text-end">{{ item.Stock.volume }}</div>
                            </template>
                            <template v-slot:item.Stock.receivedValue="{ item }">
                                <div class="text-end">{{ numberFormat(item.Stock.receivedValue, '0,0') }}</div>
                            </template>
                            <template v-slot:footer>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <tfoot>
                                        <tr class>
                                            <td class="text-end font-weight-bold" colspan="8">{{ $t('message.total') }}</td>
                                            <td class="text-end font-weight-bold" style="width: 50px !important;">{{ receivedStocksTotals.currency }}</td>
                                            <td class="text-end font-weight-bold" style="width: 85px !important;">
                                                <span class="mr-3">{{ numberFormat(receivedStocksTotals.receivedValue, '0,0') }}</span>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item key="sold-stocks-list">
                        <v-overlay
                            :value="loading.sold"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-data-table
                            :fixed-header="soldStocks.length > 10"
                            :headers="headers.sold"
                            :items="soldStocks"
                            :height="soldStocks.length > 10 ? tableHeight : null"
                            :items-per-page="-1"
                            :sort-by="'Stock.age'"
                            calculate-widths
                            class="mt-3 appic-table-light specification-table"
                            dense
                            hide-default-footer
                            id="soldStocksTable"
                            item-key="Stock.id"
                        >
                            <template v-slot:item.Stock.age="{ item }">
                                <div class="font-sm" v-if="item.Stock.age < 180">{{ item.Stock.age }}</div>
                                <div class="font-sm red--text text--darken-3" v-else>180+</div>
                            </template>
                            <template v-slot:item.Stock.volume="{ item }">
                                <div class="text-end">{{ item.Stock.volume }}</div>
                            </template>
                            <template v-slot:item.Stock.soldFreshValue="{ item }">
                                <div class="text-end">{{ numberFormat(item.Stock.soldFreshValue, '0,0') }}</div>
                            </template>
                            <template v-slot:item.Stock.soldOldValue="{ item }">
                                <div class="text-end">{{ numberFormat(item.Stock.soldOldValue, '0,0') }}</div>
                            </template>
                            <template v-slot:footer>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <tfoot>
                                        <tr class>
                                            <td class="text-end font-weight-bold" colspan="8">{{ $t('message.total') }}</td>
                                            <td class="text-end font-weight-bold" style="width: 50px !important;">{{ soldStocksTotals.currency }}</td>
                                            <td class="text-end font-weight-bold" style="width: 85px !important;">
                                                <span class="mr-3">{{ numberFormat(soldStocksTotals.soldFreshValue, '0,0') }}</span>
                                            </td>
                                            <td class="text-end font-weight-bold" style="width: 85px !important;">
                                                <span class="mr-3">{{ numberFormat(soldStocksTotals.soldOldValue, '0,0') }}</span>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-data-table>
                    </v-tab-item>
<!--                    <v-tab-item key="incoming-stocks-list">-->
<!--                        <v-overlay-->
<!--                            :value="loading.incoming"-->
<!--                            absolute-->
<!--                            opacity="0.15"-->
<!--                        >-->
<!--                            <v-row>-->
<!--                                <v-col class="text-center">-->
<!--                                    <v-progress-circular-->
<!--                                        color="primary"-->
<!--                                        indeterminate-->
<!--                                        size="40"-->
<!--                                        width="6"-->
<!--                                    />-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                        </v-overlay>-->
<!--                        <v-data-table-->
<!--                            :fixed-header="incomingStocks.length > 10"-->
<!--                            :headers="headers.incoming"-->
<!--                            :items="incomingStocks"-->
<!--                            :height="incomingStocks.length > 10 ? tableHeight : null"-->
<!--                            :items-per-page="-1"-->
<!--                            :sort-by="'Stock.age'"-->
<!--                            calculate-widths-->
<!--                            class="mt-3 appic-table-light specification-table"-->
<!--                            dense-->
<!--                            hide-default-footer-->
<!--                            id="incomingStocksTable"-->
<!--                            item-key="Stock.id"-->
<!--                        >-->
<!--                            <template v-slot:item.Stock.volume="{ item }">-->
<!--                                <div class="text-end">{{ item.Stock.volume }}</div>-->
<!--                            </template>-->
<!--                            <template v-slot:item.Stock.incomingValue="{ item }">-->
<!--                                <div class="text-end">{{ numberFormat(item.Stock.incomingValue, '0,0') }}</div>-->
<!--                            </template>-->
<!--                            <template v-slot:footer>-->
<!--                                <v-simple-table>-->
<!--                                    <template v-slot:default>-->
<!--                                        <tfoot>-->
<!--                                        <tr class>-->
<!--                                            <td class="text-end font-weight-bold" colspan="8">{{ $t('message.total') }}</td>-->
<!--                                            <td class="text-end font-weight-bold" style="width: 50px !important;">{{ incomingStocksTotals.currency }}</td>-->
<!--                                            <td class="text-end font-weight-bold" style="width: 85px !important;">-->
<!--                                                <span class="mr-3">{{ numberFormat(incomingStocksTotals.incomingValue, '0,0') }}</span>-->
<!--                                            </td>-->
<!--                                        </tr>-->
<!--                                        </tfoot>-->
<!--                                    </template>-->
<!--                                </v-simple-table>-->
<!--                            </template>-->
<!--                        </v-data-table>-->
<!--                    </v-tab-item>-->
                </v-tabs-items>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" @click="viewDialog = false" >{{$t('message.dismiss')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {capitalize, numberFormat} from "Helpers/helpers";
import {mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import {api} from "Api";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "WeeklyStocksDetail",
    props: ['detailTitle','dialog','filters'],
    components: {ExportTableJson},
    data(){
        return {
            grossTotals: [],
            incomingStocks: [],
            incomingStocksTotals: {
                currency: 'USD',
                incomingValue: 0
            },
            localCurrency: 'USD',
            loading: {
                incoming: false,
                opening: false,
                received: false,
                sold: false,
            },
            openingStocks: [],
            openingStocksTotals: {
                currency: 'USD',
                openingValue: 0,
                oldValue: 0
            },
            receivedStocks: [],
            receivedStocksTotals: {
                currency: 'USD',
                receivedValue: 0,
            },
            soldStocks: [],
            soldStocksTotals: {
                currency: 'USD',
                soldFreshValue: 0,
                soldOldValue: 0
            },
            tableHeight: '400',
            view_dialog: false,
            totalCurrentBuyingPriceLocal: 0,
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapFields('runtime', {
            weeklySalesDetailCurrentTab: 'weeklySalesDetailCurrentTab'
        }),
        headers() {
            return {
                incoming: [
                    {
                        id: 1,
                        text: capitalize(this.$t('message.eta')),
                        value: 'Stock.incomingEta',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 2,
                        text: capitalize(this.$t('message.po')),
                        value: 'Stock.po',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.product'),
                        value: 'Stock.product',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.species'),
                        value: 'Stock.species',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.claim'),
                        value: 'Stock.certification',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.grade'),
                        value: 'Stock.grade',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.qty'),
                        value: 'Stock.volume',
                        class: 'light-green lighten-3 pa-1 text-end',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 8,
                        text: this.$t('message.unit'),
                        value: 'Stock.uofmVolume',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 9,
                        text: this.$t('message.cur'),
                        value: 'Stock.currency',
                        class: 'light-green lighten-3 pa-1 width-1-pct',
                        style: "width: 50px !important",
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 10,
                        text: this.$t('message.value'),
                        value: 'Stock.incomingValue',
                        class: 'light-green lighten-3 pa-1 text-end',
                        style: "width: 85px !important",
                        sortable: true,
                        searchable: true
                    },
                ],
                opening: [
                    {
                        id: 1,
                        text: capitalize(this.$t('message.age')),
                        value: 'Stock.age',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 2,
                        text: this.$t('message.product'),
                        value: 'Stock.product',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.species'),
                        value: 'Stock.species',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.claim'),
                        value: 'Stock.certification',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.bundleNo'),
                        value: 'Stock.bundleNo',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.grade'),
                        value: 'Stock.grade',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.qty'),
                        value: 'Stock.volume',
                        class: 'light-green lighten-3 pa-1 text-end',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 8,
                        text: this.$t('message.unit'),
                        value: 'Stock.uofmVolume',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 9,
                        text: this.$t('message.cur'),
                        value: 'Stock.currency',
                        class: 'light-green lighten-3 pa-1 width-1-pct',
                        style: "width: 50px !important",
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 10,
                        text: this.$t('message.value'),
                        value: 'Stock.openingValue',
                        class: 'light-green lighten-3 pa-1 text-end',
                        style: "width: 85px !important",
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 11,
                        text: this.$t('message.oldStock'),
                        value: 'Stock.oldValue',
                        class: 'light-green lighten-3 pa-1 text-end',
                        style: "width: 85px !important",
                        sortable: true,
                        searchable: true
                    }
                ],
                received: [
                    {
                        id: 1,
                        text: capitalize(this.$t('message.age')),
                        value: 'Stock.age',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 2,
                        text: this.$t('message.product'),
                        value: 'Stock.product',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.species'),
                        value: 'Stock.species',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.claim'),
                        value: 'Stock.certification',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.bundleNo'),
                        value: 'Stock.bundleNo',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.grade'),
                        value: 'Stock.grade',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.qty'),
                        value: 'Stock.volume',
                        class: 'light-green lighten-3 pa-1 text-end',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 8,
                        text: this.$t('message.unit'),
                        value: 'Stock.uofmVolume',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 9,
                        text: this.$t('message.cur'),
                        value: 'Stock.currency',
                        class: 'light-green lighten-3 pa-1 width-1-pct',
                        style: "width: 50px !important",
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 10,
                        text: this.$t('message.freshStock'),
                        value: 'Stock.receivedValue',
                        class: 'light-green lighten-3 pa-1 text-end',
                        style: "width: 85px !important",
                        sortable: true,
                        searchable: true
                    },
                ],
                sold: [
                    {
                        id: 1,
                        text: capitalize(this.$t('message.age')),
                        value: 'Stock.age',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 2,
                        text: capitalize(this.$t('message.invoice')),
                        value: 'Stock.invoice',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 3,
                        text: capitalize(this.$t('message.buyer')),
                        value: 'Stock.buyer',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.product'),
                        value: 'Stock.product',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.species'),
                        value: 'Stock.species',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.claim'),
                        value: 'Stock.certification',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.bundleNo'),
                        value: 'Stock.bundleNo',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 8,
                        text: this.$t('message.grade'),
                        value: 'Stock.grade',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 9,
                        text: this.$t('message.qty'),
                        value: 'Stock.volume',
                        class: 'light-green lighten-3 pa-1 text-end',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 10,
                        text: this.$t('message.unit'),
                        value: 'Stock.uofmVolume',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 11,
                        text: this.$t('message.cur'),
                        value: 'Stock.currency',
                        class: 'light-green lighten-3 pa-1 width-1-pct',
                        style: "width: 50px !important",
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 12,
                        text: this.$t('message.freshStock'),
                        value: 'Stock.soldFreshValue',
                        class: 'light-green lighten-3 pa-1 text-end',
                        style: "width: 85px !important",
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 13,
                        text: this.$t('message.oldStock'),
                        value: 'Stock.soldOldValue',
                        class: 'light-green lighten-3 pa-1 text-end',
                        style: "width: 85px !important",
                        sortable: true,
                        searchable: true
                    },
                ]
            }
        },
        viewDialog: {
            get() {
                return this.view_dialog;
            },
            set(value){
                this.view_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        loadList(list) {
            let detail = null;
            switch(list) {
                case 'incoming':
                    if(this.incomingStocks.length === 0) {
                        this.loading.incoming = true;
                        detail = 'incoming';
                    }
                    break;
                case 'opening':
                    if(this.openingStocks.length === 0) {
                        this.loading.opening = true;
                        detail = 'opening';
                    }
                    break;
                case 'received':
                    if(this.receivedStocks.length === 0) {
                        this.loading.received = true;
                        detail = 'received';
                    }
                    break;
                case 'sold':
                    if(this.soldStocks.length === 0) {
                        this.loading.sold = true;
                        detail = 'sold';
                    }
                    break;
            }
            if(detail !== null) {
                api
                    .get('/reports/stocks/weekly-stock-movement/' + detail, {
                        params: {
                            conditions: this.filters
                        }
                    })
                    .then((response) => {
                        if (response.data.status === 'success') {
                            switch(list) {
                                case 'incoming':
                                    this.incomingStocks = response.data.data.stocks;
                                    this.incomingStocksTotals.incomingValue = response.data.data.totals['incomingValue'];
                                    this.incomingStocksTotals.currency = response.data.data.totals['currency'];
                                    break;
                                case 'opening':
                                    this.openingStocks = response.data.data.stocks;
                                    this.openingStocksTotals.openingValue = response.data.data.totals['openingValue'];
                                    this.openingStocksTotals.oldValue = response.data.data.totals['oldValue'];
                                    this.openingStocksTotals.currency = response.data.data.totals['currency'];
                                    break;
                                case 'received':
                                    this.receivedStocks = response.data.data.stocks;
                                    this.receivedStocksTotals.receivedValue = response.data.data.totals['receivedValue'];
                                    this.receivedStocksTotals.currency = response.data.data.totals['currency'];
                                    break;
                                case 'sold':
                                    this.soldStocks = response.data.data.stocks;
                                    this.soldStocksTotals.soldFreshValue = response.data.data.totals['soldFreshValue'];
                                    this.soldStocksTotals.soldOldValue = response.data.data.totals['soldOldValue'];
                                    this.soldStocksTotals.currency = response.data.data.totals['currency'];
                                    break;
                            }
                        } else {
                            this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        if (this.loading.incoming) this.loading.incoming = false;
                        if (this.loading.opening) this.loading.opening = false;
                        if (this.loading.received) this.loading.received = false;
                        if (this.loading.sold) this.loading.sold = false;
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        if (this.loading.incoming) this.loading.incoming = false;
                        if (this.loading.opening) this.loading.opening = false;
                        if (this.loading.received) this.loading.received = false;
                        if (this.loading.sold) this.loading.sold = false;
                    })
            }
        },
        numberFormat
    },
    watch: {
        dialog(value) {
            this.view_dialog = value
            if(value) {
                this.incomingStocks = [];
                this.openingStocks = [];
                this.receivedStocks = [];
                this.soldStocks = [];

                if (this.weeklySalesDetailCurrentTab != null) {
                    let detail = null;
                    if (this.weeklySalesDetailCurrentTab === 0) detail = 'opening';
                    if (this.weeklySalesDetailCurrentTab === 1) detail = 'received';
                    if (this.weeklySalesDetailCurrentTab === 2) detail = 'sold';
                    if (this.weeklySalesDetailCurrentTab === 3) detail = 'incoming';
                    this.loadList(detail)

                } else {
                    this.weeklySalesDetailCurrentTab = 0;
                    this.loadList('opening')
                }
            }
        }
    },
}
</script>

<style scoped>
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .text-end {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .v-data-table__wrapper {
    overflow-x: auto;
}
.v-data-table::v-deep  thead > tr > th:nth-child(1) {
    min-width: unset !important;
    background-color: #c5e1a5;
    width: 1px !important;
}
.v-data-table::v-deep .v-data-table-header th {
    white-space: nowrap;
}
</style>