var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","overlay":"false","scrollable":""},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"success white--text"},[_vm._v(_vm._s(_vm.detailTitle))]),_c('v-card-text',[_c('v-tabs',{attrs:{"color":"success","slider-color":"appic-green"},model:{value:(_vm.weeklySalesDetailCurrentTab),callback:function ($$v) {_vm.weeklySalesDetailCurrentTab=$$v},expression:"weeklySalesDetailCurrentTab"}},[_c('v-tab',{key:"opening-stocks",on:{"change":function($event){return _vm.loadList('opening')}}},[_vm._v(_vm._s(_vm.$t('message.opening')))]),_c('v-tab',{key:"received-stocks",on:{"change":function($event){return _vm.loadList('received')}}},[_vm._v(_vm._s(_vm.$t('message.received')))]),_c('v-tab',{key:"sold-stocks",on:{"change":function($event){return _vm.loadList('sold')}}},[_vm._v(_vm._s(_vm.$t('message.sold')))]),_c('v-spacer'),_c('ExportTableJson',{staticClass:"mb-1",attrs:{"export-data":{
                            openingStocks: _vm.openingStocks,
                            receivedStocks: _vm.receivedStocks,
                            soldStocks: _vm.soldStocks,
                            title: _vm.detailTitle
                        },"export-fields":_vm.headers,"export-source":'stocks-weekly-detail'}})],1),_c('v-tabs-items',{model:{value:(_vm.weeklySalesDetailCurrentTab),callback:function ($$v) {_vm.weeklySalesDetailCurrentTab=$$v},expression:"weeklySalesDetailCurrentTab"}},[_c('v-tab-item',{key:"opening-stocks-list"},[_c('v-overlay',{attrs:{"value":_vm.loading.opening,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"fixed-header":_vm.openingStocks.length > 10,"headers":_vm.headers.opening,"height":_vm.openingStocks.length > 10 ? _vm.tableHeight : null,"items":_vm.openingStocks,"items-per-page":-1,"sort-by":'Stock.age',"calculate-widths":"","dense":"","hide-default-footer":"","id":"openingStocksTable","item-key":"Stock.id"},scopedSlots:_vm._u([{key:"item.Stock.age",fn:function(ref){
                        var item = ref.item;
return [(item.Stock.age < 180)?_c('div',{staticClass:"font-sm"},[_vm._v(_vm._s(item.Stock.age))]):_c('div',{staticClass:"font-sm red--text text--darken-3"},[_vm._v("180+")])]}},{key:"item.Stock.volume",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(item.Stock.volume))])]}},{key:"item.Stock.openingValue",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.openingValue, '0,0')))])]}},{key:"item.Stock.oldValue",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.oldValue, '0,0')))])]}},{key:"footer",fn:function(){return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tfoot',[_c('tr',{},[_c('td',{staticClass:"text-end font-weight-bold",attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.$t('message.total')))]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"50px !important"}},[_vm._v(_vm._s(_vm.openingStocksTotals.currency))]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"85px !important"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.numberFormat(_vm.openingStocksTotals.openingValue, '0,0')))])]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"85px !important"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.numberFormat(_vm.openingStocksTotals.oldValue, '0,0')))])])])])]},proxy:true}])})]},proxy:true}])})],1),_c('v-tab-item',{key:"received-stocks-list"},[_c('v-overlay',{attrs:{"value":_vm.loading.received,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"fixed-header":_vm.receivedStocks.length > 10,"headers":_vm.headers.received,"items":_vm.receivedStocks,"height":_vm.receivedStocks.length > 10 ? _vm.tableHeight : null,"items-per-page":-1,"sort-by":'Stock.age',"calculate-widths":"","dense":"","hide-default-footer":"","id":"receivedStocksTable","item-key":"Stock.id"},scopedSlots:_vm._u([{key:"item.Stock.age",fn:function(ref){
                        var item = ref.item;
return [(item.Stock.age < 180)?_c('div',{staticClass:"font-sm"},[_vm._v(_vm._s(item.Stock.age))]):_c('div',{staticClass:"font-sm red--text text--darken-3"},[_vm._v("180+")])]}},{key:"item.Stock.volume",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(item.Stock.volume))])]}},{key:"item.Stock.receivedValue",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.receivedValue, '0,0')))])]}},{key:"footer",fn:function(){return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tfoot',[_c('tr',{},[_c('td',{staticClass:"text-end font-weight-bold",attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.$t('message.total')))]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"50px !important"}},[_vm._v(_vm._s(_vm.receivedStocksTotals.currency))]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"85px !important"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.numberFormat(_vm.receivedStocksTotals.receivedValue, '0,0')))])])])])]},proxy:true}])})]},proxy:true}])})],1),_c('v-tab-item',{key:"sold-stocks-list"},[_c('v-overlay',{attrs:{"value":_vm.loading.sold,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"fixed-header":_vm.soldStocks.length > 10,"headers":_vm.headers.sold,"items":_vm.soldStocks,"height":_vm.soldStocks.length > 10 ? _vm.tableHeight : null,"items-per-page":-1,"sort-by":'Stock.age',"calculate-widths":"","dense":"","hide-default-footer":"","id":"soldStocksTable","item-key":"Stock.id"},scopedSlots:_vm._u([{key:"item.Stock.age",fn:function(ref){
                        var item = ref.item;
return [(item.Stock.age < 180)?_c('div',{staticClass:"font-sm"},[_vm._v(_vm._s(item.Stock.age))]):_c('div',{staticClass:"font-sm red--text text--darken-3"},[_vm._v("180+")])]}},{key:"item.Stock.volume",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(item.Stock.volume))])]}},{key:"item.Stock.soldFreshValue",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.soldFreshValue, '0,0')))])]}},{key:"item.Stock.soldOldValue",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.soldOldValue, '0,0')))])]}},{key:"footer",fn:function(){return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tfoot',[_c('tr',{},[_c('td',{staticClass:"text-end font-weight-bold",attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.$t('message.total')))]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"50px !important"}},[_vm._v(_vm._s(_vm.soldStocksTotals.currency))]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"85px !important"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.numberFormat(_vm.soldStocksTotals.soldFreshValue, '0,0')))])]),_c('td',{staticClass:"text-end font-weight-bold",staticStyle:{"width":"85px !important"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.numberFormat(_vm.soldStocksTotals.soldOldValue, '0,0')))])])])])]},proxy:true}])})]},proxy:true}])})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){_vm.viewDialog = false}}},[_vm._v(_vm._s(_vm.$t('message.dismiss')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }